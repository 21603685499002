<template>
  <v-card flat>
    <v-card-title>Menu Builder</v-card-title>
    <v-card-text>
      This page allows you to build a new diet's menu based on the meals which are currently being produced for other
      diets.
      <br/>
      If you would like to create a new diet/stream, please go to the
      <router-link :to="{ name: 'Streams'}">Stream Page</router-link>
    </v-card-text>
    <v-toolbar flat>
      <v-text-field
        :value="datesFormatted"
        label="Select meal production dates"
        hide-details
        readonly
        @click="showDatePicker=true"
        append-icon="mdi-calendar"
        :loading="loading>0"
      />
      <v-dialog
        v-model="showDatePicker"
        ref="dialog"
        width="290px"
      >
        <v-date-picker
          v-model="datePickerDate"
          :range="isAdmin"
          no-title
          @close="!isAdmin && closeDatePicker()"
          @change="closeDatePicker"
        ></v-date-picker>
        <v-btn v-if="isAdmin" @click="closeDatePicker">Close</v-btn>
      </v-dialog>
      <v-spacer/>
      <v-select
        v-if="streams"
        label="Diet/Stream"
        v-model="streamId"
        :items="Object.values(streams)"
        item-text="name"
        item-value="id"
        @change="fetchStreamMenu"
      />
    </v-toolbar>
    <v-toolbar flat>
      <v-autocomplete
        :disabled="loadingOrChecking"
        :loading="checkingRestrictions"
        label="Check Restriction"
        v-model="selectedRestrictions"
        :items="Object.values(restrictions).flatMap(Object.values)"
        item-text="text"
        item-value="id"
        multiple
        chips
        deletable-chips
      />
      <v-chip class="ml-2" outlined v-if="isAlaCarte">Price Restriction &lt; $3.50</v-chip>
      <v-spacer/>
      <span v-if="checkingRestrictions">checking {{ mealsRaw.length }} meals for restriction</span>
      <span v-if="!checkingRestrictions">{{ meals.length }} of {{ mealsRaw.length }} meals satisfy restriction</span>
      <v-spacer/>
      <v-btn :disabled="loadingOrChecking || incompleteData" outlined @click="autoAssign">Auto Assign</v-btn>
      <v-btn :disabled="loadingOrChecking || incompleteData" class="ml-2" outlined @click="clearMenuConfirm">Clear
      </v-btn>
      <v-btn :loading="saving" :disabled="loadingOrChecking || incompleteData" class="ml-2" outlined @click="save">
        Save
      </v-btn>
    </v-toolbar>
    <v-toolbar flat>
      <v-select
        v-if="diets"
        class="ml-2"
        label="Diets"
        :items="Object.values(diets)"
        v-model="dietsToInclude"
        item-value="id"
        item-text="name"
        multiple
        chips
        deletable-chips
      />
    </v-toolbar>
    <v-card-title>
      <v-spacer/>
      {{ stream.name }}
      <v-chip class="ml-2" small color="success" v-if="stream.visible">Available on app.fedfedfed.com</v-chip>
      <v-chip class="ml-2" small color="warning" v-if="!stream.visible">Hidden</v-chip>
      <v-spacer/>
    </v-card-title>
    <v-card-text class="text-center">
      <p>{{ stream.description }}</p>
      <span
        v-for="(s,index) of (Object.values(stream.sizes||{})).sort((a,b)=>a.description.localeCompare(b.description)).filter(s => s.available)"
        v-bind:key="index">
        <span v-if="index>0"> | </span>
        {{ s.kitchen_description }} {{ s.description }} <span v-if="!s.available">NOT AVAILABLE</span>
      </span>
      <!--      {{ menuPayload }}-->
    </v-card-text>
    <template v-if="stream && stream.alacarte">
      <v-card-title>A la Carte Menu</v-card-title>
      <v-card-text v-if="!loading">
        <v-row v-for="[date] of uniqueProductionDays" v-bind:key="`${streamId}-${date}`">
          <v-col cols="2" md="1">{{ dateFormatted(date, {formatString: 'ddd, MMM DD'}) }}</v-col>
          <v-col v-for="tod of tods" v-bind:key="tod">
            <h4>{{ tod }}</h4>
            <v-row
              no-gutters dense
              v-for="meal of mealsInSameProduction(date, tod)"
              v-bind:key="meal.id">
              <v-col cols="1">
                <v-checkbox
                  dense
                  v-model="alacarte[meal.id]"
                />
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="alacartePrice[meal.id]"
                />
              </v-col>
              <v-col>
                <v-chip x-small v-if="meal.diet" text-color="white"
                        :color="getDietColor(meal.diet)">
                  {{ getDietName(meal.diet) }}
                </v-chip>
                {{ meal.name }} ({{ formatCurrency(meal.cost) }})
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
    <template v-if="menu && streamId && !stream.alacarte">
      <v-card-title>
        Fixed Slot Menu
      </v-card-title>
      <v-card-text v-for="date of getDatesInRange(dateFrom,dateTo)" v-bind:key="`${streamId}-${date}`">
        <v-row>
          <v-col cols="2" md="1">{{ dateFormatted(date, {formatString: 'ddd, MMM DD'}) }}</v-col>
          <v-col v-for="tod of tods" v-bind:key="tod">
            <h4>{{ tod }}</h4>
            <v-row>
              <v-col v-if="menu[date][tod]">
                <v-chip v-if="menu[date][tod].diet" text-color="white" small
                        :color="getDietColor(menu[date][tod].diet)">
                  {{ getDietName(menu[date][tod].diet) }}
                </v-chip>
                <template>
                  {{ formatCurrency(menu[date][tod].cost) }}
                  {{ ratings[menu[date][tod].id] ? (' - ' + ratings[menu[date][tod].id].overall.average + '★') : '' }}
                  {{ menu[date][tod].name }}
                  ({{ getTimeOfDay(menu[date][tod].tod) }})
                  <v-icon color="red" v-if="menu[date][tod].restricted">mdi-skull-crossbones</v-icon>
                </template>
                <v-btn icon @click="setMealDate(null,date,tod)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
              <v-col v-if="!menu[date][tod]">
                <v-autocomplete
                  :items="mealsInSameProduction(date, tod, true).filter(m => !m.is_selected)"
                  :item-text="i => `${i.cost ? formatCurrency(i.cost) :''} ${ratings[i.id] ? (' - ' +ratings[i.id].overall.average+'★') :''} - ${i.name} (${getDietName(i.diet)} - ${dateFormatted(i.date, {formatString: 'ddd'})} - ${getTimeOfDay(i.tod)})`"
                  item-value="id"
                  return-object
                  @change="setMealDate($event,date, tod)"
                  clearable
                  :disabled="loadingOrChecking"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
  </v-card>
</template>

<style scoped>

</style>


<script>
import moment from "moment";
import api from "@/api";
import {mapActions, mapGetters, mapState} from "vuex";
import {dateFormatted, formatCurrency, getDatesInRange, getProductionDays} from "@/store/utils";
import urlState from "@/router/urlState";

export default {
  name: "MenuBuilder",
  mixins: [urlState],
  data() {
    return {
      showCost: true,
      streamId: null,
      streams: [],
      dates: [],
      showDatePicker: null,
      loading: 0,
      datePickerDate: null,
      mealsRaw: [],
      tods: 'breakfast lunch dinner'.split(' '),
      ratingProperties: 'overall,portion,preparation,presentation,taste'.split(','),
      ratings: {},
      feedback: {},
      selectedRestrictions: null,
      restrictions: {},
      checkingRestrictions: null,
      menu: null,
      alacarte: {},
      alacartePrice: {},
      dietsToInclude: null,
      saving: null,
      alacarteMeals: null
    }
  },
  props: {
    isAdmin: {type: Object, default: null, required: false}
  },
  watch: {
    selectedRestrictions: 'checkRestrictions',
    showDatePicker(v) {
      if (v) {
        if (this.isAdmin) {
          this.datePickerDate = this.dates;
        } else {
          this.datePickerDate = this.dates[0];
        }
      } else {
        if (this.isAdmin) {
          this.dates = this.datePickerDate;
        } else {
          this.dates = [
            moment(this.datePickerDate).startOf('week').add(1, 'day'),
            moment(this.datePickerDate).endOf('week')
          ].map(d => d.format(moment.HTML5_FMT.DATE));
        }
      }
    },
    showCost(v) {
      if (v) {
        this.fetchCosts();
      } else {
        for (const meal of this.mealsRaw) {
          meal.cost = '';
        }
      }
    }
  },
  async mounted() {
    this.loading++;
    this.dietsToInclude = [1, 14, 18, 16];
    await Promise.all([
      this.fetchDiets(),
      this.fetchStreams().then(s => this.streams = s),
      this.fetchRestrictions().then(r => this.restrictions = r)
    ]);
    this.syncToUrl({
      param: 'dates', urlParam: 'dates', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'dietsToInclude', urlParam: 'diets', initFromRoute: true,
      parseCallback: (v) => (Array.isArray(v) ? v : [v]).map(v => Number(v))
    });
    this.syncToUrl({
      param: 'selectedRestrictions', urlParam: 'restrictions', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'streamId', urlParam: 'stream', initFromRoute: true,
      // parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.$nextTick(() => this
      .fetchData()
      .finally(() => {
        this.loading--;
      })
    );
  },
  methods: {
    getDatesInRange,
    getProductionDays,
    formatCurrency,
    ...mapActions(['fetchStreams', 'fetchDiets', 'fetchRestrictions']),
    dateFormatted,
    closeDatePicker() {
      this.showDatePicker = false;
      this.$nextTick(() => this.fetchData());
    },
    async fetchData() {
      const dates = this.dates.filter(d => moment(d).isValid());
      if (!(dates && dates.length > 0)) {
        console.log('no dates');
        return;
      }

      this.loading++;

      this.alacarteMeals = {};
      for (const date of dates) {
        this.alacarteMeals[date] = {};
        for (const tod of this.tods) {
          this.alacarteMeals[date][tod] = [];
        }
      }

      // reset the menu
      this.menu = {};
      this.clearMenu();

      const where = {
        date: {
          gte: `${this.dateFrom}T00:00:00.000Z`,
          lte: `${this.dateTo}T00:00:00.000Z`,
        }
      };

      const limit = 500;
      await api
        .get('v2/meal/', {
          params: {
            where,
            limit
          }
        })
        .then(({data}) => {
          if (data.count > limit) {
            alert(`more meals matched (${data.count}) than limit (${limit}) - all meals may not have been fetched!`);
          }
          this.mealsRaw = data.meals;
        })
        .then(() => {
          if (this.showCost) {
            return this.fetchCosts()
          } else {
            for (const meal of this.mealsRaw) {
              meal.cost = '';
              meal.expandFeedback = false;
            }
          }
        });

      // fetch ratings
      const mealIds = this.mealsRaw.flatMap(m => m.sku_meal_ids || [m.id]);
      const otherMealIdToMealIdMap = Object.fromEntries(this.mealsRaw.flatMap(m => m.sku_meal_ids.map(otherId => [otherId, m.id]) || [m.id, m.id]));
      // console.log(otherMealIdToMealIdMap);
      const ratings = {};

      function addRating(mealId, property, value) {
        if (value) {
          const mealRating = ratings[mealId] || (ratings[mealId] = {});
          const mealPropertyRating = mealRating[property] || (mealRating[property] = {total: 0, count: 0});
          mealPropertyRating.total += value;
          mealPropertyRating.count++;
          mealPropertyRating.average = (mealPropertyRating.total / mealPropertyRating.count).toFixed(1);
        }
      }

      await api.post('feedback/get-by-id', {mealIds})
        .then(response => {
          const feedbackRecords = response.data;
          this.feedback = {};
          for (const f of feedbackRecords) {
            const originalMealId = otherMealIdToMealIdMap[f.meal_id];
            const mealId = originalMealId;
            this.ratingProperties.forEach(property => addRating(mealId, property, f[property]))
            this.feedback[originalMealId] = this.feedback[originalMealId] || [];
            this.feedback[originalMealId].push(f);
            this.feedback[originalMealId].sort((a, b) => b.date.localeCompare(a.date));
          }
          Object.assign(this.ratings, ratings);
        });

      // const mealIds = this.mealsRaw.flatMap(m => m.sku_meal_ids || [m.id]);
      if (this.streamId) {
        await this.fetchStreamMenu();
      }
      this.loading--;
    },
    setMealDate(meal, date, tod) {
      // console.log('setMealDate', {meal, date, tod});
      if (this.menu[date][tod]) {
        this.$set(this.menu[date][tod], 'selected', false);
        this.menu[date][tod] = null;
      }
      if (meal) {
        this.menu[date][tod] = meal;
        this.$set(this.menu[date][tod], 'selected', true);
        this.$set(this.alacarte, meal.id, true);
      }
    },
    mealsInSameProduction(date, tod, includeOtherTods = false) {
      const dates = getProductionDays(date);
      const meals = this.meals
        .filter(m => dates.includes(m.date))
      const costSort = (m1, m2) => m1.cost - m2.cost;
      return [
        ...meals.filter(m => this.getTimeOfDay(m.tod) === tod).sort(costSort),
        ...(includeOtherTods ? meals.filter(m => this.getTimeOfDay(m.tod) !== tod).sort(costSort) : [])
      ];
    },
    async checkRestrictions() {
      console.log('checking restriction');
      if (this.selectedRestrictions && this.selectedRestrictions.length) {
        this.checkingRestrictions = true;
        await Promise.all(
          this.mealsRaw.map(meal => api
            .post(`v2/restrictions`, {mealIds: [meal.id], restrictionIds: this.selectedRestrictions})
            .then(r => {
              if (Object.keys(r.data).length > 0) {
                // console.log('meal restricted', meal.name, r.data);
                this.$set(meal, 'restricted', true);
              } else {
                // console.log('meal not restricted', meal.name, r.data);
                this.$set(meal, 'restricted', false);
              }
            })
          )
        );
      } else {
        this.mealsRaw.forEach(meal => this.$set(meal, 'restricted', false));
      }
      if (this.stream.alacarte) {
        // console.log('alacarte stream')
        this.mealsRaw
          .filter(m => m.cost >= 350)
          .forEach(meal => this.$set(meal, 'restricted', true));
      }
      this.checkingRestrictions = false;
    },
    autoAssign() {
      if (this.stream.alacarte) {
        for (const meal of this.meals.filter(m => m.cost > 0 && m.cost <= 350)) {
          this.$set(this.alacarte, meal.id, true);
        }
      } else {
        const menu = this.menu;
        for (const date of this.allDates) {
          for (const tod of this.tods) {
            if (!menu[date][tod]) {
              const meals = this.mealsInSameProduction(date, tod);
              if (meals.length > 0) {
                this.setMealDate(meals[0], date, tod);
              }
            }
          }
        }
      }
    },
    clearMenu() {
      this.allDates
        .forEach(date => {
          this.$set(this.menu, date, Object.fromEntries(this.tods.map(tod => [tod, null])))
        });
      this.alacarte = {};
    },
    clearMenuConfirm() {
      if (confirm('Are you sure you want to clear and start again?')) {
        this.clearMenu();
      }
    },
    save() {
      const date = moment(this.dateFrom).format(moment.HTML5_FMT.DATE);
      this.saving = true;
      return api.put(`v2/menu/${date}/${this.streamId}`, this.menuPayload)
        .then((r) => {
          console.log('saved', r);
        })
        .finally(() => {
          this.saving = false;
          return this.fetchStreamMenu();
        });
    },
    async fetchStreamMenu() {
      this.loading++;
      const date = moment(this.dateFrom).format(moment.HTML5_FMT.DATE);
      const menuOnDate = await api.get(`v2/menu/${date}`).then(r => r.data);
      console.log('loaded menu on date ' + date, menuOnDate);
      const streamMenu = menuOnDate[this.streamId] && menuOnDate[this.streamId].menu;
      if (!streamMenu) {
        this.menu = {};
        this.clearMenu();
        console.log('no menu for stream');
      } else {
        // console.log('got menu', streamMenu);
        // console.log('this.meals',this.mealsRaw);
        // console.log('this.meals',this.meals);
        console.log('fetchStreamMenu', streamMenu);
        for (const streamMenuDay of streamMenu) {
          const {date, meals} = streamMenuDay;
          for (const meal of (meals || [])) {
            const id = Number(meal.id);
            const mealRaw = this.mealsRaw.find(m => m.id === id)
            if (!mealRaw) {
              console.log('could not find local meal copy', meal, this.meals);
            }
            this.setMealDate(mealRaw, date, meal.tod);
          }
        }
      }
      await this.checkRestrictions();
      this.loading--;
    },
    getPrice(cost) {
      if (cost > 0 && cost < 250) {
        return 999;
      }
      if (cost <= 350) {
        return 1199;
      }
    },
    async fetchCosts() {
      // if (this === this) return;
      this.loading++;
      console.log('fetching costs');
      return api.post('v2/meal/get-cost', {mealIds: this.mealsRaw.map(m => m.id)})
        .then(r => r.data)
        .then(costs => {
          this.alacartePrice = {};
          for (const meal of this.mealsRaw) {
            this.$set(meal, 'cost', costs[meal.id]);
            this.$set(meal, 'expandFeedback', false);
            this.$set(this.alacartePrice, meal.id, this.getPrice(meal.cost));
          }
        })
        .finally(() => {
          console.log('fetched costs');
          this.loading--;
        });
    }
  },
  computed: {
    ...mapState(['diets']),
    ...mapGetters(['getDietName', 'getDietColor', 'getTimeOfDay']),
    uniqueProductionDays() {
      const pairs = {};
      for (const date of getDatesInRange(this.dateFrom, this.dateTo)) {
        const productionDays = getProductionDays(date).sort();
        pairs[productionDays.join(',')] = productionDays;
      }
      return Object.values(pairs);
    },
    isAlaCarte() {
      return this.stream && this.stream.alacarte;
    },
    stream() {
      return this.streamId && this.streams[this.streamId] || {};
    },
    meals() {
      let meals = this.mealsRaw.filter(m => !m.restricted);
      if (this.dietsToInclude && this.dietsToInclude.length > 0) {
        meals = meals.filter(m => this.dietsToInclude.includes(m.diet));
      }
      return meals;
    },
    datesFormatted() {
      const format = 'dddd MMMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
    allDates() {
      return getDatesInRange(this.dateFrom, this.dateTo);
    },
    loadingOrChecking() {
      return (this.loading > 0) || this.checkingRestrictions;
    },
    menuPayload() {
      const result = [];
      if (this.alacarte) {
        const dateToMealMap = {};
        for (let mealId of Object.keys(this.alacarte)) {
          if (this.alacarte[mealId]) {
            mealId = Number(mealId);
            const meal = this.meals.find(m => m.id === mealId);
            const date = moment(meal.date).format(moment.HTML5_FMT.DATE);
            const price = this.alacartePrice[mealId];
            dateToMealMap[date] = dateToMealMap[date] || [];
            const tod = Number.isInteger(meal.tod) ? this.getTimeOfDay(meal.tod) : meal.tod;
            dateToMealMap[date].push({...meal, price, tod});
          }
        }
        result.push(...Object.entries(dateToMealMap).map(([date, meals]) => ({date, meals})));
      } else {
        for (const date of Object.keys(this.menu || {})) {
          const meals = [];
          for (const tod of Object.keys(this.menu[date])) {
            const meal = this.menu[date][tod];
            if (meal) {
              meals.push({id: meal.id, tod, date});
            }
          }
          result.push({date, meals});
        }
      }
      return result;
    },
    incompleteData() {
      return !(this.streamId && this.dates && this.dates.length > 0)
    },
  },
}
</script>

